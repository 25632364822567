@import url("./variables.css");
@import-normalize;

html,
body,
#root {
    background: var(--black);
    color: var(--white);
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
#root::-webkit-scrollbar {
    display: none;
}

button {
    padding: var(--unit);
    cursor: pointer;
    outline: none;
    box-shadow: var(--shadow-1);
}
