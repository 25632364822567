.control-plane-left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30%;
    cursor: pointer;
}

.control-plane-right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30%;
    cursor: pointer;
}
