:root {
    --unit: 10px;
    --halfunit: 5px;
    --black: #000;
    --dark-gray-1: #171717;
    --dark-gray-2: #202020;
    --dark-gray-3: #252525;
    --dark-gray-4: #333;
    --white: #ddd;
    --light-gray-5: #ccc;
    --light-gray-4: #bbb;
    --light-gray-3: #aaa;
    --subitile-size: 7vmax;
    --shadow-1: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
}
