@import "../variables.css";

.subtitle-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.text-translation,
.text-info {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    height: 100%;
    width: 100%;
    font-size: var(--subitile-size);
    box-sizing: border-box;
    padding: 10vh;
}
