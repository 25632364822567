@import "../variables.css";

.recenter-button-container {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    text-align: center;
}

.recenter-button {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
}

.recenter-button svg {
    fill: var(--white);
    height: 15vh;
    width: 15vh;
    min-height: calc(var(--unit) * 4);
}

.recenter-button:hover svg,
.recenter-button:active svg {
    fill: var(--light-gray-4);
}
