@import "../variables.css";

.language-menu {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
}

.language-menu__toggle-button {
    background: var(--dark-gray-2);
    border: 0;
    color: var(--light-gray-3);
    align-self: flex-end;
    margin: var(--unit) var(--unit) 1px;
    transition: 0.1s ease-in;
    z-index: 2;
}

.language-menu__toggle-button:hover,
.language-menu__toggle-button--open {
    background-color: var(--dark-gray-1);
    transition: 0.1s ease-out;
}

.dropdown {
    max-width: 200px;
    background: var(--dark-gray-4);
    margin: 0 10px 10px;
    padding: var(--halfunit) var(--halfunit) 0;
    z-index: 1;
}

.dropdown__item-list,
.dropdown__item {
    margin: 0;
    padding: 0;
    list-style: none;
    min-width: 100px;
}

.dropdown__item__button {
    width: 100%;
    background: var(--dark-gray-3);
    border: 0;
    color: var(--light-gray-4);
    margin-bottom: var(--halfunit);
    transition: 0.1s ease-in;
}

.dropdown__item__button:hover {
    background-color: var(--dark-gray-2);
    transition: 0.1s ease-out;
}

.dropdown__item__button--active,
.dropdown__item__button--active:hover {
    background-color: var(--dark-gray-1);
    cursor: auto;
}
